import Btn, { BtnProps } from '../ui/Btn';

export type UnstyledAccordionItemLabelProps<C extends React.ElementType = 'button'> = BtnProps<C>;

const UnstyledAccordionItemLabel = ({ children, ...props }: UnstyledAccordionItemLabelProps) => {
  return (
    <Btn variant="unstyled" wrap {...props}>
      {children}
    </Btn>
  );
};

export default UnstyledAccordionItemLabel;
