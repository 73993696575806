'use client';

import { useView } from '@/lib/store/hooks';
import { PageHeaderProps } from '.';
import Txt, { TxtProps } from '../ui/Txt';
import { PageEntry } from '@/lib/parsers/entries/customEntryTypes';
import { maybeGet } from '@liquorice/utils';

export type PageTitleProps = TxtProps<'h1'> & Partial<PageHeaderProps>;

export default function PageTitle({ children, ...props }: PageTitleProps) {
  const page = useView() as PageEntry;
  const title = page?.entryTitle ?? null;

  const summary = maybeGet(page, 'entrySubTitle');

  const content = children || title;

  return (
    content && (
      <Txt as="h1" variant="h1" cx={!summary ? { mB: 'none' } : undefined} {...props}>
        {content}
      </Txt>
    )
  );
}
