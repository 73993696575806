import { NavMenuItem } from '@/lib/parsers/navigation';
import { Drawer } from '@mui/material';
import { useState } from 'react';
import Icon from '../Icon';
import IconButton from '../IconButton';
import * as styles from '../NavDrawer/NavDrawer.css';
import UnstyledAccordion from '../UnstyledAccordion';
import { UnstyledAccordionItemProps } from '../UnstyledAccordion/UnstyledAccordion.Item';
import Flex from '../ui/Flex';
import * as itemStyles from './NavDrawerItem.css';
import { useNavBar } from '../NavBar/useNavBar';

export type NavDrawerItem = UnstyledAccordionItemProps & { item: NavMenuItem; index: number };

export const NavDrawerItem = ({ item: itemProp, index }: NavDrawerItem) => {
  const { profile, shoppingBasket } = useNavBar();
  const [open, setOpen] = useState<boolean>(false);
  const { type: _, subItems, newWindow, parentId: __, href, external, ...item } = itemProp;

  const isHome = href === '/';
  const isProfile = item.id === profile?.id;
  const isShoppingBasket = item.id === shoppingBasket?.id;

  const handleClick = (newOpen: boolean) => {
    setOpen(newOpen);
  };

  return (
    <UnstyledAccordion.Item
      {...{
        index,
        className: itemStyles.item,
        colorSet: !!subItems?.length ? 'primaryTintLighter' : 'white',
        paper: true,
      }}>
      <UnstyledAccordion.ItemHandle
        index={index}
        disableHandleLink={true}
        cx={{ pX: 'sm', pY: '2xs' }}>
        <UnstyledAccordion.ItemLabel
          {...{
            variant: 'nav',
            color: 'primary',
            underline: 'none',
            weight: 'bold',
            className: itemStyles.label,
            target: newWindow || external ? '_blank' : '_self',
            href: href ?? '',
            wrap: true,
            ...item,
          }}>
          {isHome ? <Icon cx={{ fontSize: 'h4' }} name="home" /> : null}
          {isProfile ? <Icon cx={{ fontSize: 'h4' }} name="profile" /> : null}
          {isShoppingBasket ? <Icon cx={{ fontSize: 'h4' }} name="shoppingCart" /> : null}
          {item.title}
        </UnstyledAccordion.ItemLabel>
        {!!subItems?.length && (
          <UnstyledAccordion.ItemCaret
            index={index}
            openIcon="chevronRight"
            openIconProps={{
              onClick: () => handleClick(true),
              size: 'tiny',
              variant: 'icon',
              color: 'primary',
            }}
          />
        )}
      </UnstyledAccordion.ItemHandle>
      <UnstyledAccordion.ItemBody index={index} disableCollapse={true}>
        <Drawer
          anchor="right"
          open={open}
          onClose={() => handleClick(false)}
          hideBackdrop
          elevation={0}>
          <Flex as="ul" className={styles.inner}>
            <Flex cx={{ width: 'full', pX: 'sm', pY: 'sm' }} alignItems="end">
              <IconButton
                icon="chevronLeft"
                size="tiny"
                color="primary"
                onClick={() => handleClick(false)}
              />
            </Flex>
            <UnstyledAccordion>
              {subItems?.map((subItem, index) => (
                <NavDrawerItem key={subItem.id} {...{ item: subItem, index }} />
              ))}
            </UnstyledAccordion>
          </Flex>
        </Drawer>
      </UnstyledAccordion.ItemBody>
    </UnstyledAccordion.Item>
  );
};
