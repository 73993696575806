import React from 'react';
import Btn, { BtnComponentWithRef } from '../ui/Btn';

// export type CtaButtonProps = Link;

const CtaButton: BtnComponentWithRef = React.forwardRef(function CtaButton(props, ref) {
  return (
    <Btn
      ref={ref}
      variant="text"
      weight="bold"
      endIcon="chevronRight"
      EndIconProps={{}}
      {...props}
    />
  );
});

export default CtaButton;
