'use client';

import { Slide, SlideProps, useScrollTrigger } from '@mui/material';

export type HideOnScrollProps = {
  children: React.ReactElement;
  in?: boolean;
} & SlideProps;

const HideOnScroll = ({ in: customIn, children, ...props }: HideOnScrollProps) => {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={customIn ?? !trigger} {...props}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
