'use client';

import useScrollPosition from '@/lib/utils/useScrollPosition';
import { assignInlineVars } from '@vanilla-extract/dynamic';

import { useView } from '@/lib/store/hooks';
import { useEffect } from 'react';
import useResizeObserver from 'use-resize-observer';
import HideOnScroll from '../HideOnScroll';
import Box, { BoxProps } from '../ui/Box';
import Container, { ContainerProps } from '../ui/Container';
import * as styles from './NavBar.css';
import { useNavbarActions } from './useNavbarState';
import { mergePropsClassName } from '@liquorice/utils';

type InnerProps = BoxProps<
  'div',
  {
    InnerProps?: BoxProps;
    ContainerProps?: ContainerProps;
  }
>;

export default function NavInner({ children, InnerProps, ContainerProps, ...props }: InnerProps) {
  const { setHeight, fallbackHeight, posColorSet, setPosColorSet } = useNavbarActions();
  const { ref, height = fallbackHeight } = useResizeObserver<HTMLDivElement>({
    onResize: ({ height }) => {
      setHeight(height);
    },
  });

  const pos = useScrollPosition();
  const sectionHandle = useView()?.sectionHandle;
  const eventUri = sectionHandle === 'event';

  useEffect(() => {
    setPosColorSet(eventUri && pos < 10);
  }, [eventUri, pos, setPosColorSet]);

  return (
    <HideOnScroll>
      <Box
        ref={ref}
        {...mergePropsClassName(props, styles.wrapper)}
        style={assignInlineVars({
          [styles.navbarHeight]: `${height ?? fallbackHeight}px`,
        })}>
        <Box
          paper
          {...mergePropsClassName(
            InnerProps,
            styles.inner({
              withViewColorTheme: pos > 10,
              withBoxShadow: eventUri ? pos > 10 : true,
              withColorSet: posColorSet,
            })
          )}>
          <Container {...mergePropsClassName(ContainerProps, styles.navbar)}>{children}</Container>
        </Box>
      </Box>
    </HideOnScroll>
  );
}
