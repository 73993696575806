'use client';

import { Player } from '@lottiefiles/react-lottie-player';
import Logo from '../../../public/animations/GAC-Logo-FA-2a.json';
import NextAnchor, { NextAnchorProps } from '../ui/NextAnchor';
import * as styles from './NavBar.css';
import { useNavbarActions } from './useNavbarState';
import { mergePropsClassName } from '@liquorice/utils';
import { useView } from '@/lib/store/hooks';
import { isEntry } from '@/lib/parsers/entries';

type NavLogo = Omit<NextAnchorProps, 'href'>;

export default function NavLogo(props: NavLogo) {
  const withColorSet = useNavbarActions((s) => s.posColorSet);
  const page = useView();
  const isEvent = isEntry(page, 'event');

  return (
    <NextAnchor
      href="/"
      aria-label="logo"
      {...mergePropsClassName(props, styles.logo({ withColorSet: withColorSet && !isEvent }))}>
      <Player hover src={Logo} className={styles.animation} />
    </NextAnchor>
  );
}
