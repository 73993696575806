'use client';

import React from 'react';
import { useNavbarActions } from './useNavbarState';
import Shim, { ShimProps } from '../Shim';
import { useView } from '@/lib/store/hooks';
import * as styles from './NavBar.css';
import { assignInlineVars } from '@vanilla-extract/dynamic';
import { mergePropsClassName } from '@liquorice/utils';

export default function NavShim(props: ShimProps) {
  const { height, fallbackHeight } = useNavbarActions();

  const sectionHandle = useView()?.sectionHandle;
  const eventUri = sectionHandle === 'event' || sectionHandle === 'educationalEvent';

  return (
    <Shim
      relative={false}
      {...mergePropsClassName(props, styles.navShim({ overlay: eventUri }))}
      style={assignInlineVars({ [styles.navbarHeight]: `${height ?? fallbackHeight}px` })}
    />
  );
}
